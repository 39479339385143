import { createSlice } from '@reduxjs/toolkit';

const renamedSensorsSlice = createSlice({
  name: 'renamedSensors',
  initialState: {},
  reducers: {
    setRenamedSensors: (state, action) => {
      return action.payload;
    },
    clearRenamedSensors: () => {
      return {};
    }
  }
});

export const { setRenamedSensors, clearRenamedSensors } = renamedSensorsSlice.actions;
export default renamedSensorsSlice.reducer;
