import { createSlice } from '@reduxjs/toolkit';

const sensorGroupSlice = createSlice({
  name: 'sensorGroup',
  initialState: {},
  reducers: {
    setSensorGroups: (state, action) => {
      return action.payload;
    },
    clearSensorGroups: () => {
      return {};
    }
  }
});

export const { setSensorGroups, clearSensorGroups } = sensorGroupSlice.actions;
export default sensorGroupSlice.reducer;
