import { createSlice } from '@reduxjs/toolkit';

const tagsSlice = createSlice({
  name: 'tags',
  initialState: [],
  reducers: {
    setTags: (state, action) => {
      return action.payload;
    },
    clearTags: () => {
      return [];
    }
  }
});

export const { setTags, clearTags } = tagsSlice.actions;
export default tagsSlice.reducer;
