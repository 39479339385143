// reloadSlice.js
import { createSlice } from '@reduxjs/toolkit';

const reloadSlice = createSlice({
    name: 'reload',
    initialState: {
        reloadKey: 0,
        forceReload: false,
    },
    reducers: {
        triggerReload: (state, action) => {
            state.reloadKey += 1; // Increment the reloadKey to force a reload
            state.forceReload = action.payload; // Set the forceReload flag based on the payload
        },
        resetReload: (state) => {
            state.reloadKey = 0; // Reset the reload
            state.forceReload = false; // Reset the forceReload flag
        },
    },
});

export const { triggerReload, resetReload } = reloadSlice.actions;
export default reloadSlice.reducer;
