/*** * Imports * ***/

import axios from 'axios';

/*** * Values * ***/

let ConfiguratorData = {};

/*** * Utils * ***/

async function whoami(options = {}) {

    /*** * Variables * ***/

    let url;

    /*** * * ***/

    // Trick to get current base url
    await fetch("/favicon.ico").then((res) => { url = new URL(res.url) }).catch(err => { url = new URL(err.url) });

    /*** * * ***/

    if (options?.port) {
        url.port = options.port
    }

    /*** * * ***/

    return url.origin;
}

/*** * Exports * ***/

export default async function Configurator() {
    /*** * Constants * ***/

    // Order sources by priority, less to high
    const sources = ["/configurator_static.json", "/configurator_entrypoint.json"];

    /*** * Variables * ***/

    let result = {};

    /*** * Return ConfiguratorData.Result if it's already calculated * ***/

    if (ConfiguratorData.Result) {
        return ConfiguratorData.Result;
    }

    /*** * Read Sources * ***/

    for (let source of sources) {
        await axios.get(source).then(res => {
            if (typeof res.data === 'object' && res.data !== null) {
                for (let i = 0; i < Object.keys(res.data).length; i++) {
                    result[(Object.keys(res.data)[i])] = Object.values(res.data)[i];
                }
            }
        }).catch(err => {
            console.error("Configurator : Error fetching \"" + source + "\" data : ", err);
        });
    }

    /*** * * ***/

    if ((!result?.API_URL) || (result?.API_URL.length === 0) || (result?.API_URL === "-1")) {
        result.API_URL = await whoami();
    }

    if ((!result?.API_WSS_ENABLE) || (result?.API_WSS_ENABLE.length === 0) || (result?.API_WSS_ENABLE === "-1")) {
        result.API_WS_PREFIX = "ws"
    } else {
        result.API_WS_PREFIX = "wss"
    }

    /*** * Add /api * ***/

    result.API_URL = `${result.API_URL}/api`;

    
    /*** * Save to ConfiguratorData.Result * ***/

    ConfiguratorData.Result = result;

    /*** * Return ConfiguratorData.Result * ***/

    console.log("Configurator : ", ConfiguratorData.Result);

    return ConfiguratorData.Result;
}
