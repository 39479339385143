import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const user = localStorage.getItem('userID');
      if (user) {
        setIsLoggedIn(true);

        let serial = localStorage.getItem('serial');
        const options = JSON.parse(localStorage.getItem("serials")) || [];
        if (!options.includes(serial) && serial !== '-1 ') {
          options.push(serial);
        }
        localStorage.setItem("serials", JSON.stringify(options));
      }
      setLoading(false);
    })();

    document.querySelector("#index-loading").style.display = "none";
  }, []);

  return { isLoggedIn, setIsLoggedIn, loading };
};

export default useAuth;
