import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function LocationHandler({ liveFlag, setSimulationData }) {
    const location = useLocation();
    const previousPathRef = useRef(location.pathname);
  
    useEffect(() => {
      if (previousPathRef.current === '/dashboard' && location.pathname !== '/dashboard') {
        liveFlag.current = true;
        setSimulationData({});
      }
      previousPathRef.current = location.pathname;
    }, [location.pathname, liveFlag, setSimulationData]);
  
    return null;
  }