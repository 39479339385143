import Configurator from "../../config/Configurator";
import axios from "axios";

export const fetchMeta = async (configName, endpoint = '/v2/db/sqlite/tables/meta/get_one') => {
    var userID = localStorage.getItem("userID");

    try {
        let config = {
            method: 'post',
            url: (await Configurator()).API_URL + endpoint,
            headers: {
                ...axios.defaults.headers.common,
                ...{
                    'Api-Key': localStorage.getItem("apiKey"),
                    'User-Id': localStorage.getItem("userID"),
                }
            },
            data: JSON.stringify({
                "entity_id": parseInt(userID),
                "entity_table": "users",
                "name": configName,
            })
        };
        const response = await axios.request(config);
        return response.data.data; // Assuming this is the correct path to your data
    } catch (error) {
        console.error("Failed to fetch renamed sensors", error);
        return {}; // Return an empty object in case of error
    }
};