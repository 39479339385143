import { createSlice } from '@reduxjs/toolkit';

const sensorMultiplier = createSlice({
  name: 'sensorMultiplier',
  initialState: {},
  reducers: {
    setSensorMultiplier: (state, action) => {
      return action.payload;
    },
 
  }
});

export const { setSensorMultiplier } = sensorMultiplier.actions;
export default sensorMultiplier.reducer;
