import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const liveDataSlice = createSlice({
    name: 'liveData',
    initialState,
    reducers: {
        setLiveData: (state, action) => {
            return action.payload;
        },
        clearLiveData: () => {
            return {};
        }
    }
});

export const { setLiveData, clearLiveData } = liveDataSlice.actions;
export default liveDataSlice.reducer;
